import Stack from "@mui/material/Stack";
import {Autocomplete, CircularProgress, Collapse, Drawer, Grid, IconButton, TextField} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import {ReactNodeLike} from "prop-types";
import {useState, ChangeEvent, useEffect, KeyboardEvent, useMemo} from "react";
import RecipeList from "@/app/components/RecipeList";
import {accountList, DishDto} from "@/app/actions";
import {Recipe} from "@/app/models/Recipe";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import DeleteIcon from '@mui/icons-material/Delete';
import {
    addIngredientsToDish,
    getIngredientsForDish,
    removeIngredientFromRecipe
} from "@/app/actions/ingredients";
import ListItem from "@mui/material/ListItem";
import IngredientsAutocomplete from "@/app/components/IngredientsAutocomplete";

type Props = {
    dish: DishDto;
    showRecipe: boolean;
    updateWeekMenuTitle: any;
    onUpdateIngredients: (value: string | undefined) => unknown;
    isLoading: any;
    accounts: accountList,
    onSelectRecipe: (recipe: Recipe) => unknown;
};

export default function DayOfWeekEdit({
                                          dish,
                                          showRecipe = false,
                                          updateWeekMenuTitle,
                                          onUpdateIngredients,
                                          isLoading,
                                          accounts,
                                          onSelectRecipe,
                                      }: Props): ReactNodeLike {

    const urlOrTitle = (url: string | undefined | null, title: string | undefined) => {
        if (url && url.length > 0) {
            return url;
        }

        return title ?? '';
    }

    const [tempTitle, setTempTitle] = useState(urlOrTitle(dish.recipe_url, dish.title));
    const [tempIngredients, setTempIngredients] = useState<{ name: string, id: string }[]>([]);
    const [tempIngredient, setTempIngredient] = useState<string>('');

    const [open, setOpen] = useState(false);

    const toggleDrawer = (newOpen: boolean) => () => {
        setOpen(newOpen);
    };

    async function selectRecipe(recipe: Recipe) {
        await onSelectRecipe(recipe);
        setOpen(false)
    }
    const fetchIngredients = async () => {
        const ingredients = await getIngredientsForDish(dish);

        if (ingredients.length) {
            setTempIngredients(ingredients)
        }
    }

    useEffect(() => {
        fetchIngredients().catch(console.error);
    }, [dish])

    const createIngredient = async (i: {id: string} | string) => {
        const ingredients = await addIngredientsToDish(dish, i);
        setTempIngredients(ingredients);
        setTempIngredient('');
    }

    const removeIngredient = async (id: string) => {
        await removeIngredientFromRecipe(dish.recipe_id!, id, accounts);
        await fetchIngredients();
    }

    return (<>
        <Grid container spacing={2}>
            <Grid item xs={2} sm={2}>
                <IconButton onClick={toggleDrawer(true)} title="Kies uit favorieten">
                    <ReceiptLongIcon></ReceiptLongIcon>
                </IconButton>
            </Grid>
            <Grid item xs>
                <Stack direction="row">
                    <TextField label="titel of url" variant="standard" sx={{flexGrow: 1}}
                               value={tempTitle ?? ''}
                               onChange={(event: React.ChangeEvent<HTMLInputElement>) => setTempTitle(event.target.value)}
                               onBlur={() => updateWeekMenuTitle(tempTitle)}/>

                    <IconButton aria-label="reset" size="small" onClick={() => setTempTitle('')} title="Titel wissen">
                        <ClearIcon></ClearIcon>
                    </IconButton>
                    {isLoading && <CircularProgress/>}
                </Stack>
            </Grid>
        </Grid>
        <Collapse in={showRecipe} timeout="auto" unmountOnExit>
            <Grid container spacing={2} marginTop={1}>
                <Grid item xs={2} sm={2}>
                </Grid>
                <Grid item xs>
                    <List>
                        {tempIngredients.map(l => (
                            <ListItem key={l.id} secondaryAction={
                                <IconButton edge="end" aria-label="delete" onClick={() => removeIngredient(l.id)}>
                                    <DeleteIcon />
                                </IconButton>
                            }>
                                <ListItemText primary={l.name} key={l.id}/>
                            </ListItem>
                            )
                        )
                        }
                    </List>

                    <IngredientsAutocomplete accounts={accounts} onAddIngredient={createIngredient}>

                    </IngredientsAutocomplete>
                </Grid>
            </Grid>
        </Collapse>
        <Drawer open={open} onClose={toggleDrawer(false)} anchor="bottom">
            <RecipeList accounts={accounts} onSelect={selectRecipe}></RecipeList>
        </Drawer>
    </>)
}

import {useEffect, useMemo, useState} from "react";
import {ingredientCompletions} from "@/app/actions/ingredients";
import debounce from "@mui/utils/debounce";
import {Autocomplete, TextField} from "@mui/material";
import {accountList} from "@/app/actions";

type IngredientLabel = {
    id: string;
    label: string;
}

type Props = {
    accounts: accountList;
    onAddIngredient: (ingredient: {id: string}|string) => Promise<void>;
}

export default function IngredientsAutocomplete({accounts, onAddIngredient}: Props) {
    const [value, setValue] = useState<IngredientLabel|string>('');
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState<Array<IngredientLabel|string>>([]);

    const fetch = useMemo(
        () =>
            debounce(
                (
                    request: { input: string },
                    callback: (results?: readonly IngredientLabel[]) => void,
                ) => {
                    ingredientCompletions(request.input, accounts)
                        .then(results => callback(results))
                },
                400,
            ),
        [accounts],
    );

    // @ts-ignore
    useEffect(() => {
        let active = true;

        if (inputValue === '') {
            setOptions([]);
            return undefined;
        }

        fetch({ input: inputValue }, (results?: readonly IngredientLabel[]) => {
            if (active) {
                let newOptions: Array<IngredientLabel|string> = [];

                if (value) {
                    newOptions = [value];
                }

                if (results) {
                    newOptions = [...newOptions, ...results];
                }

                setOptions(newOptions);
            }
        });

        return () => active = false;
    }, [value, inputValue, fetch]);

    return (<>
        <Autocomplete<IngredientLabel | string, false, true, true>
            freeSolo
            disableClearable
            filterOptions={(x) => x}
            options={options}
            getOptionLabel={(option) =>
                typeof option === 'string' ? option : option?.label
            }
            autoComplete
            filterSelectedOptions
            value={value}
            noOptionsText="Geen ingredienten gevonden"
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Zoek ingredient"
                    InputProps={{
                        ...params.InputProps,
                        type: 'search',
                    }}
                />
            )}
            onChange={async (event: any, newValue: IngredientLabel | string) => {
                await onAddIngredient(newValue);
                setInputValue('');
                setValue('');
            }}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
        />
    </>)
}

'use client';

import {Box, Button, Card, CardActions, CardContent, CardHeader, Typography} from "@mui/material";
import {styled} from '@mui/material/styles';

import {ReactNodeLike} from "prop-types";
import {useState} from "react";
import {
    DishDto,

} from "@/app/actions";
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import DayOfWeekEdit from "@/app/components/DayOfWeekEdit";
import DayOfWeekShow from "@/app/components/DayOfWeekShow";
import IconButton, {IconButtonProps} from '@mui/material/IconButton';
import {FixedMenu} from "@/app/models/FixedMenu";
import {Recipe} from "@/app/models/Recipe";
import {createDish, setDishFromFavoriteRecipe, updateDish} from "@/app/actions/dishes";

export type WeekDay = {
    date: Date;
    dayOfWeek: number;
    timestamp: number;
    formatted: string;
    formattedDate: string;
}

function startOfDay(date: Date | string | number): Date {
    const clonedDate = new Date(date);
    clonedDate.setUTCHours(0, 0, 0, 0);
    return clonedDate;
}

interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
    const {expand, ...other} = props;
    return <IconButton {...other} />;
})(({theme, expand}) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginRight: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

export default function DayOfWeek({weekday, currentMenu, accountId, userId, fixedMenus}: {
    weekday: WeekDay,
    currentMenu: DishDto[],
    fixedMenus: FixedMenu[],
    accountId: string,
    userId: string,
}): ReactNodeLike {
    const [expanded, setExpanded] = useState(false);
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    function getMenuForDate(date: Date): DishDto {
        const current = currentMenu.find((menu) => {
            const mn = startOfDay(menu.timestamp);
            const dt = startOfDay(date);

            return mn.getTime() === dt.getTime();
        });

        if (current) {
            return current
        }

        const fixedMenu = fixedMenus.find(menus => Number(menus.repeat_pattern) === date.getDay());
        if (fixedMenu) {
            return {
                type: 'fixed',
                id: fixedMenu.id,
                title: fixedMenu.title,
                timestamp: date,
                account_id: "c1108f23-9d11-42fe-9039-fbab4f1be0bc",
                created_by_user_id: "24bc5b73-abab-405e-abb9-4fa0b33155a8",
            }
        }

        return {
            id: '',
            account_id: accountId,
            created_by_user_id: userId,
            timestamp: date,
            title: '',
            type: 'single'
        }
    }

    const m = getMenuForDate(weekday.date);

    const [dish, setDish] = useState<DishDto>(m);
    const [isEditing, setIsEditing] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const selectRecipe = async (recipe: Recipe) => {
        setIsLoading(true);

        const result = await setDishFromFavoriteRecipe(recipe, m, userId);

        setDish((menu) => ({
            ...menu,
            title: result.title,
            recipe_url: result.recipe_url
        } as DishDto));
        setIsEditing(false);
        setIsLoading(false);
    }


    const save = async () => {
        setIsLoading(true)

        const newDish: DishDto = {
            account_id: accountId ?? "c1108f23-9d11-42fe-9039-fbab4f1be0bc",
            created_by_user_id: userId ?? "24bc5b73-abab-405e-abb9-4fa0b33155a8",
            id: dish?.type === 'fixed' ? '' : dish?.id,
            recipe_url: dish?.recipe_url && dish?.recipe_url.length > 0 ? dish.recipe_url : undefined,
            timestamp: weekday.date,
            title: dish?.title ?? '',
            type: dish?.type === 'fixed' ? 'single' : dish?.type,
            ingredients: dish?.ingredients
        };

        if (newDish.id && newDish.id !== '') {
            const result = await updateDish(newDish);
            console.info('menu updated', result);

            setDish((menu) => ({
                ...menu,
                title: result.title,
                recipe_url: result.recipe_url,
                ingredients: result.ingredients
            }));
        } else {
            const result = await createDish(newDish);

            console.info('menu created', result);
            setDish((menu) => ({
                ...menu,
                id: result.id,
                title: result.title,
                recipe_url: result.recipe_url,
                ingredients: result.ingredients
            } ));
        }

        setIsEditing(false)
        setIsLoading(false)
        setExpanded(false);
    }

    const cancel = () => {
        setDish(m);

        setIsEditing(false);
        setIsLoading(false);
    }


    const updateWeekMenuTitle =
        (title: string): void => {
            if (URL.canParse(title)) {
                const url = new URL(title);
                setDish((menu) => ({...menu, title: title, recipe_url: url.toString()} as DishDto));
            } else {
                setDish((menu) => ({...menu, title, recipe_url: ''} as DishDto))
            }
        }

        const updateIngredients = (input: string|undefined): void => {
            setDish((menu) => ({...menu, ingredients: input}));
        }

    const toggleEdit = () => {
        setIsEditing(true)
    }

    return <Card sx={{my: 2, p: 1}}>
        <CardHeader title={
            <Box sx={{display: 'flex'}} justifyContent="space-between">
                {weekday.formatted}
            </Box>
        }
        subheader={
            weekday.formattedDate
        }
        action={
            <>
                {!isEditing && <IconButton aria-label="save" size="small" onClick={toggleEdit}>
                    <EditIcon></EditIcon>
                </IconButton>}
            </>
        }>
        </CardHeader>
        <CardContent>
            {isEditing &&
                <DayOfWeekEdit accounts={[{accountId}]}
                               dish={dish}
                               updateWeekMenuTitle={updateWeekMenuTitle}
                               onUpdateIngredients={updateIngredients}
                               isLoading={isLoading}
                               onSelectRecipe={selectRecipe}
                               showRecipe={expanded}
                >
                </DayOfWeekEdit>}

            {!isEditing &&
                <DayOfWeekShow dish={dish} accountId={accountId} userId={userId}></DayOfWeekShow>
            }
        </CardContent>

        {isEditing && <CardActions disableSpacing>
            {dish.type !== 'fixed' && <ExpandMore
                expand={expanded}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
            >
                <ExpandMoreIcon/>
            </ExpandMore>}

            {dish.type === 'fixed' && <Typography variant={'caption'} marginRight={'auto'}>Dit is een vast gerecht</Typography>}
            <Button size="small" onClick={cancel}>Annuleren</Button>
            <Button size="small" onClick={save}>Opslaan</Button>
        </CardActions>}
    </Card>
}

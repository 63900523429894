import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/vercel/path0/node_modules/@mui/icons-material/ShoppingCart.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/material/AppBar/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/material/Box/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/material/Button/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/material/Container/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/material/Grid/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/material/Link/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/@mui/material/Toolbar/Toolbar.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/material/Typography/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/components/BottomNavigation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/components/DayOfWeek.tsx");
